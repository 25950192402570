<template>
    <div class="d-flex justify-content-center">
        <b-row class="container">
            <b-col>
                 <b-card class="text-center main">
                    <b-card class="main">
                        <img :src="logo_pbbkb" alt="pbbkb" width="50%" class="icon-hover">
                    </b-card>
                    <h6 class="py-3 text-ani">
                      Sistem Aplikasi Pendaftaran, Pendataan, Penyetoran dan Pelaporan Pajak Bahan Bakar Kenderaan Bermotor Daerah Provinsi Sumatera Utara
                    </h6>
                </b-card>
            </b-col>
            <b-col>
                <b-card class="text-center main">
                    <b-card class="main">
                        <img :src="logo_apu" alt="pbbkb" width="50%" class="icon-hover">
                    </b-card>
                    <h6 class="py-3 text-ani">
                      Sistem Aplikasi Pendaftaran, Pendataan, Penyetoran dan Pelaporan Pajak Air Permukaan Provinsi Sumatera Utara
                    </h6>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import logo_pbbkb from '@/assets/logo_pbbkb.png'
import logo_apu from '@/assets/log_apu.png'

export default {
    data () {
        return {
            logo_pbbkb: logo_pbbkb,
            logo_apu: logo_apu
        }
    }    
}
</script>

<style scoped>
.main {
    cursor: pointer;
    border-radius: 20px;
}

.icon-hover {
    transform: scale(1);
    transition: all 0.3s linear;
}

.text-ani {
    transform: translateY(0px);
    transition: all 0.3s linear;
}

.main:hover .icon-hover {
    transform: scale(1.2);
}

.main:hover .text-ani {
    transform: translateY(20px);
}
</style>