import { render, staticRenderFns } from "./DashboardPajak.vue?vue&type=template&id=cb948ce6&scoped=true&"
import script from "./DashboardPajak.vue?vue&type=script&lang=js&"
export * from "./DashboardPajak.vue?vue&type=script&lang=js&"
import style0 from "./DashboardPajak.vue?vue&type=style&index=0&id=cb948ce6&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb948ce6",
  null
  
)

export default component.exports