<template>
    <div>
        <h-retribusi />
        <div v-show="dataNpwpd !== null && appShow">
            <Verifikasi />
        </div>
        <div v-show="user.npwpd_id === null || formShow">
            <!-- Panel start here -->
            <panel
                title="FORM REGISTRASI WAJIB PAJAK"
                class="panel panel-danger"
            >
                <b-alert
                    variant="warning"
                    show
                    v-if="dataNpwpd !== null"
                >
                    <h5 class="font-weight-bold text-center" v-if="dataNpwpd">
                        Data anda saat ini masih dalam status "{{
                            dataNpwpd.status_registrasi.nama
                        }}".
                    </h5>
                    <p class="text-center">
                        Silahkan tekan tombol "Kirim Permohonan" setelah selesai
                        melengkapi data.
                    </p>
                </b-alert>
                <div class="nav-wizards-container">
                    <nav class="nav nav-wizards-1 mb-2">
                        <div class="nav-item col">
                            <router-link
                                :to="{ name: 'StepProfil' }"
                                v-slot="{ href, navigate }"
                            >
                                <a
                                    :class="{
                                        'nav-link': true,
                                        active: isActive,
                                        completed: isCompleted,
                                    }"
                                    :key="href"
                                    @click="navigate"
                                    style="cursor: pointer;"
                                >
                                    <div class="nav-no">
                                        1
                                    </div>
                                    <div class="nav-text">
                                        Registrasi NPWPD
                                    </div>
                                </a>
                            </router-link>
                        </div>
                        <div class="nav-item col">
                            <router-link
                                :to="{ name: 'StepPimpinan' }"
                                v-slot="{ href, navigate }"
                            >
                                <a
                                    :class="{
                                        'nav-link': true,
                                        active: isActive2,
                                        disabled: isDisable,
                                        completed: isCompleted2,
                                    }"
                                    :key="href"
                                    @click="navigate"
                                    style="cursor: pointer;"
                                >
                                    <div class="nav-no">
                                        2
                                    </div>
                                    <div class="nav-text">
                                        Pimpinan
                                    </div>
                                </a>
                            </router-link>
                        </div>
                        <div class="nav-item col">
                            <router-link
                                :to="{ name: 'StepPenanggungJawab' }"
                                v-slot="{ href, navigate }"
                            >
                                <a
                                    :class="{
                                        'nav-link': true,
                                        active: isActive3,
                                        disabled: isDisable,
                                        completed: isCompleted3,
                                    }"
                                    :key="href"
                                    @click="navigate"
                                    style="cursor: pointer;"
                                >
                                    <div class="nav-no">
                                        3
                                    </div>
                                    <div class="nav-text">
                                        Penanggung Jawab
                                    </div>
                                </a>
                            </router-link>
                        </div>
                    </nav>
                </div>
                <!-- template view for step -->
                <div class="card">
                    <div class="card-body">
                        <transition name="slide-fade">
                            <router-view :detailProfil="detail" />
                        </transition>
                    </div>
                </div>
                <div class="text-center py-4">
                    <b-button
                        variant="success"
                        :disabled="isKirim"
                        @click="kirimPermohonan(dataNpwpd.id)"
                        size="lg"
                    >
                        <span v-if="loadingPermohonan">
                            <b-spinner variant="primary" small></b-spinner>
                        </span>
                        <i class="fa fa-envelope" v-else></i>
                        Kirim Permohonan
                    </b-button>
                </div>
            </panel>
            <!-- Panel end here -->
        </div>
    </div>
</template>

<script>
/* eslint-disable */
import Logo from "@/assets/images/logoSumut.png";
import PageOptions from "@/config/PageOptions.vue";
import Verifikasi from "../Verifikasi.vue";
import FormReg from ".././FormRegWajibPajak.vue";
import axios from "axios";
import { mapState } from "vuex";
import HRetribusi from "@/components/header/HRetribusi.vue";

export default {
    components: {
        Verifikasi,
        FormReg,
        HRetribusi,
    },
    data() {
        return {
            imageLogo: Logo,
            form: new Form({
                bentuk_badan_id: "",
                jenis: "",
                nama: "",
                nomp: "",
                no_induk: "",
                notelepon: "",
                email: "",
                alamat: "",
                kelurahan_id: null,
                kodepos: "",
            }),

            /* // variabel form rekening
            rekening: {
                kodeNama: '',
                kodeRekening: '0'
            }, */
            errors: [],
            message: null,
            npwpdOptions: [],
            provinsiOptions: [],
            kotaOptions: [],
            kecamatanOptions: [],
            kelurahanOptions: [],

            input: {
                kota: null,
                kecamatan: null,
                provinsi: null,
            },
            detail: null,
            user: JSON.parse(localStorage.getItem("user")),
            npwpdID: null,
            editMode: false,
            appShow: false,
            formShow: false,
            isLoading: false,
            isJenis: true,
            isActive: true,
            isActive2: false,
            isActive3: false,
            isCompleted: false,
            isCompleted2: false,
            isCompleted3: false,
            loadingPermohonan: false,
            no_pokok: '',
        };
    },
    created() {
        PageOptions.pageEmpty = false;
        document.body.className = "";
        this.stepClass();
    },
    computed: {
        ...mapState([
            "isDisable", 
            "isKirim",
            "dataNpwpd" 
        ]),
    },
    updated() {
        this.stepClass();
    },
    mounted() {
        if (this.user.npwpd_id === null) {
            this.$swal({
                icon: "info",
                title: "Data Wajib Pajak Anda Belum Diregistrasi",
                text:
                    "Silahkan registrasi terlebih dahulu pada form registrasi",
                showCloseButton: true,
                showConfirmButton: true,
                confirmButtonText: "OKE",
            });
            this.editMode = false;
            this.$store.dispatch("isEventKirim", true);
            this.$store.dispatch("isEventDisable", false);
        } else {
            this.detailNpwpd(this.user.npwpd_id);
            this.$store.dispatch('updateNpwpd', this.user.npwpd_id)
        }
        this.getProvinsi();
    },
    methods: {
        // when npwpd id exist
        detailNpwpd(id) {
            axios
                .get("/api/wajib-pajak/npwpd/" + id)
                .then((response) => {
                    this.detail = response.data.data;
                    this.npwpdID = this.detail.id
                    if (this.detail.jenis > 41) {
                        this.$store.dispatch("isEventDisable", true);
                        this.$store.dispatch("isEventKirim", false);
                    } else if  (
                        this.detail.jenis < 41
                    ) {
                        this.$store.dispatch("isEventDisable", false);
                        this.$store.dispatch("isEventKirim", false);
                    }
                    if (this.detail.status_registrasi_id !== 5) {
                        this.formShow = true;
                    } else {
                        this.appShow = true;
                        this.formShow = false;
                    }
                    // console.log(this.detail);
                })
                .catch((error) => {
                    console.log(error);
                    if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                });
        },

        // input
        inputJenis(value) {
            if (value == "Perorangan") {
                this.isJenis = false;
            } else {
                this.isJenis = true;
            }
        },

        //Wilayah
        // saat provinsi di input
        onProvinsi(id) {
            if (this.input.provinsi === null) {
                this.kotaOptions = [];
                this.input.kota = null;
                this.kecamatanOptions = [];
                this.input.kecamatan = null;
            } else {
                this.getKabupaten(id);
            }
        },
        // saat kab./kota di input
        onKabKota(id) {
            if (this.input.kota === null) {
                this.kecamatanOptions = [];
                this.input.kecamatan = null;
            } else {
                this.getKecamatan(id);
            }
        },

        // saat kab./kota di input
        onKecamatan(id) {
            if (this.input.kecamatan === null) {
                this.kecamatanOptions = [];
                this.input.kecamatan = null;
            } else {
                this.getKelurahan(id);
            }
        },

        // Provinsi
        getProvinsi() {
            axios
                .get("/api/data-induk/wilayah-indonesia/provinsi/getoption")
                .then((response) => {
                    this.provinsiOptions = response.data.data;
                    /* console.log(this.provinsiOptions) */
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                });
        },
        // Kabupaten
        getKabupaten(id) {
            axios
                .get(
                    "/api/data-induk/wilayah-indonesia/kabkota/getoption/provinsi-" +
                        id
                )
                .then((response) => {
                    /* console.log(response.data.data) */
                    this.kotaOptions = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                });
        },
        // Kecamatan
        getKecamatan(id) {
            axios
                .get(
                    "/api/data-induk/wilayah-indonesia/kecamatan/getoption/kabkota-" +
                        id
                )
                .then((response) => {
                    /*  console.log(response.data.data) */
                    this.kecamatanOptions = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                });
        },

        // Kelurahan
        getKelurahan(id) {
            axios
                .get(
                    "/api/data-induk/wilayah-indonesia/kelurahan/getoption/kecamatan-" +
                        id
                )
                .then((response) => {
                    /* console.log(response.data.data) */
                    this.kelurahanOptions = response.data.data;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        localStorage.removeItem("EP_token");
                        this.$router.push({ name: "Login" });
                    }
                });
        },

        // reset
        reset() {
            this.form.reset();
            this.input.provinsi = null;
            this.input.kota = null;
            this.input.kecamatan = null;
            this.input.kabupaten = null;
        },
        // step
        stepClass() {
            switch (this.$route.name) {
                case "StepProfil":
                    this.isActive = true;
                    this.isActive2 = false;
                    this.isActive3 = false;

                    this.isCompleted = true;
                    this.isCompleted2 = false;
                    this.isCompleted3 = false;
                    break;
                case "StepPimpinan":
                    this.isActive = false;
                    this.isActive2 = true;
                    this.isActive3 = false;

                    this.isCompleted = true;
                    this.isCompleted2 = true;
                    this.isCompleted3 = false;
                    break;
                case "StepPenanggungJawab":
                    this.isActive = false;
                    this.isActive2 = false;
                    this.isActive3 = true;

                    this.isCompleted = true;
                    this.isCompleted2 = true;
                    this.isCompleted3 = true;
                    break;
                default:
                    this.isActive = false;
                    this.isActive = false;
                    this.isActive = false;

                    this.isCompleted = false;
                    this.isCompleted2 = false;
                    this.isCompleted3 = false;
            }
        },
        // kirim permohonan
        kirimPermohonan(id) {
            this.loadingPermohonan = true
            const input = {
                id: '',
                no_pokok: ''
            }
            input.id = String(this.npwpdID)
            input.no_pokok = this.dataNpwpd.no_pokok
            if (this.dataNpwpd.status_registrasi_id === 2) {
                this.loadingPermohonan = false
                this.$swal({
                        icon: "warning",
                        title: "Data Anda Dalam Proses Permohonan.",
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        showCloseButton: true,
                        confirmButtonText: "OKE",
                        confirmButtonClass: "btn btn-primary",
                        timer: 5000,
                    })
            } else {
                axios
                .put("/api/wajib-pajak/npwpd/kirim-permohonan/" + id, input)
                .then((response) => {
                    this.loadingPermohonan = false
                    this.$store.dispatch('updateNpwpd', id)
                    this.$swal({
                        icon: "success",
                        title: "Permohonan anda berhasil dikirim.",
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        showCloseButton: true,
                        confirmButtonText: "OKE",
                        confirmButtonClass: "btn btn-primary",
                        timer: 5000,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.$store.dispatch('updateNpwpd', id)
                        }
                    });
                })
                .catch((error) => {
                    this.loadingPermohonan = false
                    console.log(error);
                    this.$swal({
                        icon: "error",
                        title: "Terjadi Masalah.",
                        text:
                            "Silahkan hubungi bagian Administrator Pajak Daerah.",
                        confirmButtonClass: "btn btn-info",
                        showConfirmButton: true,
                        showCloseButton: true,
                        confirmButtonText: "OKE",
                        confirmButtonClass: "btn btn-primary",
                        timer: 5000,
                    });
                });
            }
        },
    },
};
</script>

<style scoped>
.nav-no:hover {
    transform: scale(1.5);
}
.slide-fade-enter-active {
  transition: all .4s ease;
}
.slide-fade-leave-active {
  transition: all .4s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-fade-enter, .slide-fade-leave-to{
  transform: translateX(10px);
  opacity: 0;
}
</style>
