var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h-retribusi'),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.dataNpwpd !== null && _vm.appShow),expression:"dataNpwpd !== null && appShow"}]},[_c('Verifikasi')],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.user.npwpd_id === null || _vm.formShow),expression:"user.npwpd_id === null || formShow"}]},[_c('panel',{staticClass:"panel panel-danger",attrs:{"title":"FORM REGISTRASI WAJIB PAJAK"}},[(_vm.dataNpwpd !== null)?_c('b-alert',{attrs:{"variant":"warning","show":""}},[(_vm.dataNpwpd)?_c('h5',{staticClass:"font-weight-bold text-center"},[_vm._v(" Data anda saat ini masih dalam status \""+_vm._s(_vm.dataNpwpd.status_registrasi.nama)+"\". ")]):_vm._e(),_c('p',{staticClass:"text-center"},[_vm._v(" Silahkan tekan tombol \"Kirim Permohonan\" setelah selesai melengkapi data. ")])]):_vm._e(),_c('div',{staticClass:"nav-wizards-container"},[_c('nav',{staticClass:"nav nav-wizards-1 mb-2"},[_c('div',{staticClass:"nav-item col"},[_c('router-link',{attrs:{"to":{ name: 'StepProfil' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
return [_c('a',{key:href,class:{
                                    'nav-link': true,
                                    active: _vm.isActive,
                                    completed: _vm.isCompleted,
                                },staticStyle:{"cursor":"pointer"},on:{"click":navigate}},[_c('div',{staticClass:"nav-no"},[_vm._v(" 1 ")]),_c('div',{staticClass:"nav-text"},[_vm._v(" Registrasi NPWPD ")])])]}}])})],1),_c('div',{staticClass:"nav-item col"},[_c('router-link',{attrs:{"to":{ name: 'StepPimpinan' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var href = ref.href;
                                var navigate = ref.navigate;
return [_c('a',{key:href,class:{
                                    'nav-link': true,
                                    active: _vm.isActive2,
                                    disabled: _vm.isDisable,
                                    completed: _vm.isCompleted2,
                                },staticStyle:{"cursor":"pointer"},on:{"click":navigate}},[_c('div',{staticClass:"nav-no"},[_vm._v(" 2 ")]),_c('div',{staticClass:"nav-text"},[_vm._v(" Pimpinan ")])])]}}])})],1),_c('div',{staticClass:"nav-item col"},[_c('router-link',{attrs:{"to":{ name: 'StepPenanggungJawab' }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                                var href = ref.href;
                                var navigate = ref.navigate;
return [_c('a',{key:href,class:{
                                    'nav-link': true,
                                    active: _vm.isActive3,
                                    disabled: _vm.isDisable,
                                    completed: _vm.isCompleted3,
                                },staticStyle:{"cursor":"pointer"},on:{"click":navigate}},[_c('div',{staticClass:"nav-no"},[_vm._v(" 3 ")]),_c('div',{staticClass:"nav-text"},[_vm._v(" Penanggung Jawab ")])])]}}])})],1)])]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('transition',{attrs:{"name":"slide-fade"}},[_c('router-view',{attrs:{"detailProfil":_vm.detail}})],1)],1)]),_c('div',{staticClass:"text-center py-4"},[_c('b-button',{attrs:{"variant":"success","disabled":_vm.isKirim,"size":"lg"},on:{"click":function($event){return _vm.kirimPermohonan(_vm.dataNpwpd.id)}}},[(_vm.loadingPermohonan)?_c('span',[_c('b-spinner',{attrs:{"variant":"primary","small":""}})],1):_c('i',{staticClass:"fa fa-envelope"}),_vm._v(" Kirim Permohonan ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }